/* Home */
.profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: 25px;
    background-color:var(--main-text-white);
    color: var(--bg-color-black);
    overflow: hidden;
}
  
.profile-content-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
}
  
.profile-image-container {
    padding: 15px;
    margin-left: 50px;
}
  
.profile-image-container img {
    width: 50vw;
    border-radius: 3px;
}
  
.profile-description {
    width: 50%;
    flex: 2;
    padding: 15px;
    margin-right: 50px;
}
  
.profile-description h1 {
    font-size: 2.3em;
    margin-bottom: 15px;
}
  
.profile-description h3 {
    font-size: 1.5em;
    margin-bottom: 25px;
    font-weight: normal;
}
  
.profile-description p {
    font-size: 1em;
    line-height: 1.6;
    margin: 25px 0;
    text-align: justify;
}
  
.button-container {
    text-align: right;
}
  
.learn-more-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1em;
    color: var(--main-text-white);
    background-color: var(--bg-color-green);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
}
  
.profile-description .learn-more-button:hover {
    background-color: var(--bg-color-green);
}

/* 991px */
@media (max-width: 991px) {
    .profile-content-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .profile-image-container {
        padding: 15px;
        margin-left: 0px;
    }
    .profile-image-container img {
        width: 80vw;
        border-radius: 3px;
    }
    .profile-description {
        width: 80%;
        margin: 0px;
    }
}

@media (max-width: 500px) {
    .profile-description h1 {
        font-size: 1.5em;
        margin-bottom: 15px;
    }
      
    .profile-description h3 {
        font-size: 1.2em;
        margin-bottom: 25px;
        font-weight: normal;
    }
}

@media (max-width: 500px) {
    
}
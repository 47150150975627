/* Podcast */
.podcast-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.podcast-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  width: 100%;
}

.podcast-logo-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center ;
  justify-content: center;
}

.podcast-logo {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center ;
  justify-content: center;
}

.BCLogo {
  height: 80%;
  width: 45%;
  margin-top: 15px;
}

.podcast-description {
  margin: 25px 20% 0px;
  text-align: center;
}

.podcast-description h5 {
  font-size: 17px;
}

.podcast-episodes {
  width: 100%;
  height: 100%;
  margin: 10px;
}

.account-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 70%;
}

.account-links a {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 17%;
  margin: 10px;
}

.Links {
  height: 100%;
  width: 100%;
}

.episodes-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin: 10px auto;
  max-width: 1200px;
  width: 90%;
  box-sizing: border-box;
}

.episode-card {
  background-color: #f9f9f9;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}

.episode-image {
  height: 75%;
  object-fit: cover;
  border-bottom: 1px solid #ddd;
  transition: opacity 0.3s ease-in-out;
}
.episode-description {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 75%;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.episode-card.hovered .episode-description {
    opacity: 1;
}

.episode-details {
  display: flex;
  flex-direction: row;
  padding: 10px;
  text-align: center;
}

.episode-title {
  font-size: 1rem;
  margin: 10px 0;
}
.episode-link {
  color: black;
  text-decoration: none;
}

.play-button {
  background-color: #ffffff;
  color: black;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9rem;
}

.play-button:hover {
  background-color:#bbdeb6;
}

.episode-button-div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-right: 50px;
}

.cards-title{
  display: flex;
  justify-content: center;
  margin: 10px;
  font-size: 2.25rem;
}
.episode-button {
  display: flex; 
  width: fit-content;
  padding: 10px;
  color: rgb(0, 0, 0);
  font-size: 16px; 
  text-align: center;
}


@media (max-width: 992px) {
  .episodes-grid {
    grid-template-columns: repeat(2, 1fr);
    width: 90%;
  }

  .podcast-description {
    margin: 15px 50px;
  }

  .BCLogo {
    width: 50%;
  }

  .account-links a {
    width: 25%;
    margin: 10px;
  }
}

@media (max-width: 662px) {
  .episodes-grid {
    grid-template-columns: repeat(1, 1fr);
    width: 90%;
  }
  .podcast-description {
    margin: 10px;
  }

  .podcast-description h5 {
    font-size: 14px;
  }
  .podcast-logo-container {
    flex-direction: column;
  }
  .BCLogo {
    width: 75%;
  }
  .account-links {
    width: 100%;
  }

  .account-links a {
    width: 25%;
    margin: 10px;
  }
}

@media (max-width: 500px) {
  .episodes-grid {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
    width: 90%; 
  }

  .podcast-description {
    margin: 7.5px;
  }

  .podcast-description h5 {
    font-size: 10px;
  }

  .podcast-logo-container {
    flex-direction: column;
  }

  .BCLogo {
    width: 100%;
  }

  .account-links {
    width: 100%;
  }

  .account-links a {
    width: 25%;
    margin: 10px;
  }
}

@media (max-width: 490px) {
  .mobile-hide {
      display: none;
  }
}


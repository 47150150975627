/* contact me */
.contact-conatiner {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-top: 25px;
  background-color: var(--main-text-white);
  overflow: hidden;
}
.img-form-conatiner{
  display: flex;
  flex-direction: row;
  width: 100%;
}
.contact-img-container{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}
.contact-img {
  width: 70%;
  margin: 10px;
}
.contact-form {
  width: 50%;
  padding: 15px;
  color: var(--main-text-black);
}
.form-div {
  width: 100%;
  padding-right: 25px;
  margin-top: 30px;
  height: auto;
  background-color: var(--main-text-white);
  display: flex;
  flex-direction: column;
}


/* Style for the submit button */
.submit-button {
  align-self: flex-end; 
}


@media (max-width: 991px) {

}

@media (max-width: 500px) {
  .img-form-conatiner{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .contact-img {
    width: 90vw;
    margin: 0px;
  }
  .contact-form {
    width: 100%;
  }
  .submit-button {
    align-self: center; 
  }
}
/* Press */
.press-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
}
.press-container h1{
    align-self: flex-start;
    margin-left: 12.5%;
}

.articles {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 75%;
}

.article {
    display: flex;
    align-items: center;
    background-color: #e8e8e8;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.article-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 0px;
    margin-right: 20px;
}

.article-content {
    flex: 1;
}

.article-content h2 {
    margin: 0 0 10px 0;
    font-size: 18px;
    color: #333;
}

.article-content p {
    margin: 0 0 10px 0;
    color: #666;
}

.article-content a {
    color: #010101;
    text-decoration: none;
}

.article-content a:hover {
    text-decoration: underline;
}

.footer {
    margin-top: 40px;
}



/* 991px */
@media (max-width: 991px) {

}

/* 500px */
@media (max-width: 500px) {
    .articles {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 95%;
    }
    .article {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .article-content h2 {
        margin: 10px;
    }
    
    .article-content p {
        margin: 10px;
    }
    
    .article-content a {
        margin: 10px;
    }
    .article-image {
        margin: 25px 25px;
    }
    .press-container h1{
        align-self: flex-start;
        margin-left: 10px;
    }
}
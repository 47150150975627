:root {
  --bg-color-green: #4d5c56;
  --bg-color-teal: #4f9ea7;
  --bg-color-yellow: #fbb216 ;
  --bg-color-grey: #353535 ;
  --bg-color-pink: #c8988d ;
  --main-text-black: #000000;
  --main-text-white:rgb(255, 255, 255);
  --font-family: 'Bodoni Moda', serif;
}

*{
  margin: 0;
  padding: 0;
}

/* NavBar */
.navbar-custom{
  display: flex;
  flex-direction: row;
  min-height: 6rem;
  padding: 0px 25px  0px 25px;
  border-bottom: #000000 solid;
}

.navbar-collapse{
  display: flex;
  flex-direction: column;
}
.Logo{
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  color: #000000A6;
  font-size: 1.5rem;
  margin: 0;
}


/* NavBar */
.navbar-custom{
  display: flex;
  flex-direction: row;
  min-height: 6rem;
  padding: 0px 25px  0px 25px;
  border-bottom: #000000 solid;
}

.navbar-collapse{
  display: flex;
  flex-direction: column;
}
.navbar-brand {
  font-size: 2.5rem;
}

/* Footer */
.footer {
  background-color: inherit;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px;
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
}

.socialIcons{
  display: flex;
  justify-content: center;
  align-items: center;
}
.icons-div{
  padding: 7px;
}
.icons {
  color: rgb(41, 41, 41)
}

/* Error Page */
.error-page{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
}
.error-page h2{
  font-size: 75px;
}
.error-page p{
  font-size: 20px;
}
.error-btn{
  width: 100px;
  color: #000000;
}


@media (max-width: 500px) {
  .navbar-collapse {
    text-align: left;
  }
  .navbar-links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  .navbar-links .nav-link {
    width: 100%;
    padding: 0.5rem 1rem;
    text-align: left;
  }

  .footer {
    margin-top: 0px;
  }
}